.card._characters{
  //background-image: url(../img/Glenhurst.jpg);
  background-color: black;
  background-attachment: fixed;
}
section.card._characters
{
    background-position: bottom left;
}
.char{
  font-size: 0.50em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:nth-of-type(2n){
    flex-direction: row-reverse;
  }
}
.card-title.header_char
{
    padding-left:40px;
}
/*.char__title{
  font-size: 3.75em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  text-shadow: 3px 2px 0 $color-shadow-title;
}*/
.char__img{
  position: relative;
  z-index: 1;
  box-sizing: none;
  &._roland{
    width: 34.5em;
    margin: -16.3em -5em -7em 0;
  }
  &._lulu{
    width: 36.5em;
    margin: -5em 0em -5em -8em;
  }
  &._aksel{
    width: 40.75em;
    margin: -15em -8em -15em 0;
  }
  &._lucius{
    width: 50.5em;
    margin: 5em 0 0em -17em;
  }
}
.char-descr{
  position: relative;
  max-width: 85em;
  padding: 2.5em 0 2.5em 15em; 
  background: linear-gradient(to bottom, rgba(255, 198, 84, 0.7), rgba(255, 198, 84, 0.4));
}
.char__mini{
  position: absolute;
  &._roland{
    width: 5.625em;
    top: -1.25em;
    left: 10.5em;
  }
  &._lulu{
    width: 5.25em;
    top: -0.75em;
    left: 12em;
  }
  &._aksel{
    width: 6.5em;
    top: -0.75em;
    left: 9.25em;
  }
  &._lucius{
    width: 15.25em;
    top: -7.25em;
    left: 5em;
  }
}
.char__who{
  padding: 5px 0 5px 50px;
  font-size: 4.25em;
  margin-bottom: 20px;
  color: #ffdd96;
  background: linear-gradient(to right, rgba(255, 198, 84, 1), rgba(255, 198, 84, 0) 70%);
}
.char__name{
  color: #f88c39;
  text-shadow: 3px 1px 0 #9e3717;
  text-transform: uppercase;
}
.char__type {
    color: #ffffff;
}
.char__about{
  padding-right: 240px;
  font-size: 1.875em;
  background: linear-gradient(to left, rgba(255, 198, 84, 0.8), rgba(255, 198, 84, 0) 70%);
}


@media all and #{$screen-large}{
  .char{
    font-size: 12px;
  }
}
@media all and #{$screen-medium}{
  .char{
    font-size: 10px;
  }
  .char__img._lucius{
    width: 45.5em;
    margin-top: 10em;
  }
}
@media all and #{$screen-tablet}
{
    .char {
        font-size: 8px;
    }

    .char__img._aksel {
        margin-left: -8em;
        margin-top: -16em;
    }

    .char__img._lucius {
        width: 60em;
        /*margin-right: 0em;*/
        margin-top: -2em;
    }

    .char__about {
        padding-right: 180px;
    }
}

@media all and #{$screen-mobile}{
  .card._characters{
    min-height: auto;
  }
  .char{
    position: relative;
    font-size: 16px;
    overflow: hidden;
  }
  .char__img{
    position: absolute;
    max-height: 80%;
    top: 15%;
    &._roland{
      width: auto;
      margin: 0;
      left: -5%;
    }
    &._lulu{
      width: auto;
      margin: 0;
      right: 7%;
    }
    &._aksel{
      width: auto;
      margin: 0;
      left: -12%;
    }
    &._lucius{
      width: auto;
      margin: 0;
      right: -21%;
    }
  }
  .char-descr{
    position: relative;
    max-width: 100%;
    padding: 0; 
    background: none;
  }
  .char__mini{
    display: none;
  }
  .char__who{
    padding: 5px 0;
    text-align: center;
    font-size: 3em;
    margin-bottom: 1.5em;
    color: #ffdd96;
    background: linear-gradient(to right, rgba(255, 198, 84, 1), rgba(255, 198, 84, 0));
  }
  .char__about{
    padding: 20px 30px 20px 35%;
    min-height: 350px;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    background: linear-gradient(to bottom, rgba(255, 198, 84, 0.7), rgba(255, 198, 84, 0.4)),
                linear-gradient(to right, rgba(255, 198, 84, 0.6), rgba(255, 198, 84, 0)) no-repeat;
    background-size: cover, 100% 40%;
    background-position: 0, 0 35%;
  }
  .char:nth-of-type(2n){
    .char__who{
      background: linear-gradient(to left, rgba(255, 198, 84, 1), rgba(255, 198, 84, 0));
    }
    .char__about{
      padding-left: 30px;
      padding-right: 35%;
      background: linear-gradient(to bottom, rgba(255, 198, 84, 0.7), rgba(255, 198, 84, 0.4)),
                  linear-gradient(to left, rgba(255, 198, 84, 0.6), rgba(255, 198, 84, 0)) no-repeat;
      background-size: cover, 100% 40%;
      background-position: 0, 0 35%;
    }
  }
}
@media all and #{$screen-small}{
  .char__about{
    padding: 30px 20px 50px 35%;
    font-size: 1.25em;
  }
  .char__img{
    &._roland{
      left: 4%;
    }
    &._lulu{
      right: -1%;
    }
    &._aksel{
      left: -20%;
    }
    &._lucius{
      right: -25%;
      top: 25%;
      max-height: 70%;
    }
  }
}
@media all and #{$screen-minimal}{
  .char__img{
    display: none;
  }
  .char__mini{
    display: block;
    &._roland{
      width: auto;
      height: 6em;
      top: 2.75em;
      left: 50%;
      transform: translateX(-50%);
    }
    &._lulu{
      width: auto;
      height: 6em;
      top: 2.75em;
      left: 50%;
      transform: translateX(-50%);
    }
    &._aksel{
      width: auto;
      height: 6em;
      top: 2.75em;
      left: 50%;
      transform: translateX(-50%);
    }
    &._lucius{
      width: auto;
      height: 6em;
      top: 2.75em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .char__who{
    font-size: 2em;
  }
  .char__about{
    font-size: 1.125em;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2em;
  }
  .char:nth-of-type(2n){
    .char__about{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .card-title.header_char
    {
        color:#f88c39;
    }
  
}