@font-face {
    font-family: icon-font;
    src: url(/fonts/icon-font.woff);
}

[data-icon]:before {
    font-family: "icon-font" !important;
}

.icon-twitter:before {
    content: "d";
}

.icon-facebook:before {
    content: "a";
}

.icon-linkedin:before {
    content: "b";
}

    .social-share ul li a:before {
    color: #ffffff;
    line-height: 20px;
    font-size: 20px;
}

.social-share span {
    display: block;
    float: left;
    vertical-align: middle;
    padding-right: 22px;
    font-family: "DIN-Bold",arial,helvetica,sans-serif;
    font-size: 16px;
    font-weight: normal !important;
    line-height: 20px;
    text-transform: uppercase;
    border-right: 1px solid #000;
}

.social-share ul li a {
    padding-left: 20px;
}

.social-share ul li {
    display: block;
    float: left;
}
.social_center {
    margin: 0 auto;
    width: 20em;
    list-style: none;
    color: #2ba6cb;
    line-height: inherit;
    text-decoration: none;
}