.card._title {
    background-image: url(../img/WebHeader.png);
    padding-top: 45%;
    background-size: cover;
}

.card-title {
    font-size: 3.75em;
    text-transform: uppercase;
    //text-shadow: 3px 2px 0 #03393b;
    text-align: center;
}

.card-text {
    font-size: 1.5em;
}

.bottom-buffer {
    padding-bottom: 50px;
}

.card {
    margin-bottom: 5px;
}

.blackbackground {
    background-color: black;
}

section.card:not(:last-of-type) {
    border-bottom: 4px solid #fff;
}

.smallborderraduis {
    border-radius: 5px;
}

.shaded {
    background-color: rgba(0,0,0,.7);
    padding: 10px;
}

.float-right {
    float:right;
}

.float-left {
    float:left;
}

.hidden {
    display: none;
}

.float-none {
    clear: both;
}

._calltoaction {
    display: none !important;
    Form {
        padding-left:15px;
    }
}


.copyright
{
    position: absolute;
    bottom: 30px;
    margin-left: 15%;
}
@media (max-width: 780px)
{
    .copyright
    {
        bottom: 22px;
        margin-left: 0;
        font-size: 1em;
    }
}


@media all and #{$screen-medium}{
    .card-text {
        font-size: 1.4em;
    }
  }
  @media all and #{$screen-tablet}
  {
    .card-text {
        font-size: 1.3em;
    }
  }
  
  @media all and #{$screen-mobile}{
    .card-text {
        font-size: 1.25em;
    }
    ._calltoaction {
        display: block;
    }
    .subscribeModal {
        display:none;
    }
  }
  @media all and #{$screen-small}{
    .card-text {
        font-size: 1.2em;
    }
  }
  @media all and #{$screen-minimal}{
    .angelic {
        width: 100%;
    }
  }











  .footer{
    position: relative;
    background: url(../img/Boat-animation.gif) 20% 60% no-repeat,
                url(../img/water-animation.gif) 50%; 
        background-size: 250px, 100px;
        height: 230px;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: -15px;
      width: 100%;
      left: 0;
      height: 57px;
      background: url(../img/footer_top_pattern.png) 50% 0 repeat-x;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0;
      height: 20px;
      background: url(../img/footer_bottom_pattern.png) 50% 100% repeat-x;
    }
  }
  .footer-inner{
    max-width: 800px;
    padding: 75px 30px 50px;
    margin: 0 auto;
    overflow: hidden;
  }
  .press-kit{
    max-width: 310px;
    // color: $color-main;
    font-size: 1.625em;
    margin: 5px auto 0;
    text-align: center;
  }
  .btn._press-kit{
    width: 246px;
    height: 72px;
    line-height: 65px;
    font-size: 1.25em;
    background-image: url(../img/btn_presskit.png);
    text-shadow: 3px 1px 0 #080667;
    &:hover{
      text-shadow: 5px 3px 0 #080667;
    }
  }
  .socials{
    float: right;
    max-width: 300px;
    text-align: center;
  }
  .social-link{
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 50px;
    margin: 0 5px;
    opacity: 0.9;
    transition: opacity 0.2s ease-in-out;
    &:hover{
      opacity: 1;
    }
  }
  .link__img{
    display: block;
    width: 100%;
  }
  .dev-logo{
    display: block;
    max-width: 70%;
    margin: 10px auto 0;
  }
  
  @media all and #{$screen-large}{
    .footer{
      background-position: 20% 60%, 50%;
    }
  }
  @media all and #{$screen-mobile}{
    .footer{
      background: url(../img/footer_bg.png) 50%;
    }
    .footer-inner{
      display: flex;
      flex-direction: column;
    }
    .press-kit{
      max-width: 100%;
      margin-bottom: 20px;
    }
    .socials{
      float: none;
      max-width: 100%;
      order: 2;
      margin: 0 auto;
    }
    .dev-logo{
      display: inline-block;
      max-width: 100%;
      width: 160px;
      margin: -12px 10px;
    }
  }
  @media all and #{$screen-minimal}{
    .dev-logo{
      display: block;
      max-width: 100%;
      width: 160px;
      margin: 15px auto 0;
    }
  }