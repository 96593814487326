.subscribeModal {
    position: absolute;
    right: 10px;
    width: 450px;
    top: 350px;
    p {
        color: white;
        font-family: 'Open Sans', sans-serif;
        font-size: 2em;
    }
    button {
        margin-top:15px;
    }
}